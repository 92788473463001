@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --primary-color-base: #353535;
        --primary-color-100: #FDFDFD;

        --secondary-color-base: #DDCC92;
        --secondary-color-100: #FBFBFA;
        --secondary-color-200: #C4A745;
        --secondary-color-300: #F7F5ED;

        --tertiary-color-base: #2372CE;
        --tertiary-color-100: #4499FD;
        --tertiary-color-200: #057BCF;
        --tertiary-color-900: #CAE9FF;

        --success-color-base: #57C421;
        --success-color-100: #D3EFE3;
        --success-color-200: #21B175;

        --info-color-base: #4499FD;
        --info-color-100: #E0F0FF;

        --warning-color-base: #E9A84E;
        --warning-color-100: #FFF5E5;

        --error-color-base: #F43F5E;
        --error-color-100: #FFEBEC;

        --neutralPrimary-color-base: #8D8D8D;
        --neutralPrimary-color-100: #F6F9FB;
        --neutralPrimary-color-200: #E7E7E7;
        --neutralPrimary-color-300: #B3B2B2;

        --neutralSecondary-color-base: #353535;
        --neutralSecondary-color-0: #FFFFFF;
        --neutralSecondary-color-100: #F0F4F6;

        --logo-url: '';
        --radio-button-height: '';
        --header-height: '';
        --header-max-height: 90px;
        --header-color: #FFFFFF;
        --header-underline-offset: '';
        --header-elements-color: #353535;
        --primary-button-color: #2D57D6;
        --primary-button-text: rgb(255 255 255 / 1);
        --secondary-button-color: #2D57D6;
        --secondary-button-text: rgb(255 255 255 / 1);
        --font-header: "'Poppins', 'Source Sans pro', sans-serif";
        --content-max-width: 1920px;
    }
}

@layer utilities {
    .max-w-content {
        max-width: var(--content-max-width);
    }
}

/* SCROLLBAR */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #DAE2E8 #FFFFFF;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
}
*::-webkit-scrollbar-thumb {
    background-color: #DAE2E8;
    border-radius: 10px;
}
*::-webkit-scrollbar-track {
    background: #FFFFFF;
    border-radius: 10px;
}

/* HIDE INPUT NUMBER ARROWS */
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
